import React from 'react';

import { Section } from '../../../components';
import { Heading } from '../../../components/styled';
import { Layout, SEO } from '../../../layout';
import Offers from './components/offers';

const description =
  'With Fidel API’s core products as your foundation, apply modular add-ons to complement your offering. Use add-ons to evolve the user experience, get to market faster and more.';

export default function AddOns() {
  return (
    <Layout>
      <SEO title="Add-ons" description={description} />
      <Section
        maxWidth={1360}
        paddingTablet="50px 30px 125px"
        paddingDesktop="50px 116px 125px"
        bgColor="#f8f8f8"
      >
        <div className="flex baseline narrow">
          <Heading as="h1" size={48}>
            Add-ons
          </Heading>
          <p>{description}</p>
        </div>
      </Section>

      <Offers />
    </Layout>
  );
}
