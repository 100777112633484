import React from 'react';

import { CodeWindow, Link, OverlayTrigger } from '../../../../components';
import Benefits, { Benefit } from '../../../../components/benefits';
import {
  CTAContainer,
  CtaTitle,
  FeatureCTA,
  Section,
  SectionFooter,
  Strong,
} from '../../../../components/styled';

import ArrowIcon from '../../../../static/icons/arrow.svg';
import CheckIcon from '../../../../static/icons/tick.svg';

export interface ProductSectionProps {
  title: string;
  text: React.ReactNode;
  benefits: Benefit[];
  ctaId?: string;
  ctaTitle?: string;
  ctaBtnText?: string;
  ctaTarget?: string;
  ctaUrl?: string;
  codeBlock?: string;
  codeBlockTitle?: string;
  docsUrl?: string;
  showDocs?: boolean;
}

export interface Customer {
  name: string;
  logo: string;
}

const ProductSection: React.FC<ProductSectionProps> = ({
  title,
  text,
  benefits,
  codeBlock,
  codeBlockTitle,
  ctaTitle,
  ctaBtnText,
  ctaTarget,
  ctaUrl,
  ctaId,
  docsUrl,
  showDocs = true,
}) => (
  <Section
    maxWidth={1360}
    padding="30px 25px"
    paddingTablet="50px 30px 125px"
    paddingDesktop="50px 116px 125px"
    bgColor="#f8f8f8"
  >
    <div className="flex baseline narrow">
      <div className="one-third">
        <h2 id={title.toLowerCase()}>{title}</h2>
      </div>
      <h3>{text}</h3>
      <Benefits items={benefits} compact icon={<CheckIcon />} />
      {codeBlock && <CodeWindow title={codeBlockTitle} value={codeBlock} />}
      {showDocs && (
        <p>
          <Link to={`/docs/select/${docsUrl || title.toLowerCase()}`}>
            <Strong weight={500}>Read the docs</Strong>
          </Link>
        </p>
      )}
      <SectionFooter>
        {ctaTitle && ctaBtnText && (
          <CTAContainer className="max-600-above-tablet" marginBottom="20px">
            <CtaTitle>{ctaTitle}</CtaTitle>
            {ctaTarget && ctaId && (
              <FeatureCTA as={OverlayTrigger} target={ctaTarget} id={ctaId}>
                {ctaBtnText} <ArrowIcon />
              </FeatureCTA>
            )}
            {ctaUrl && (
              <FeatureCTA as={Link} to={ctaUrl}>
                {ctaBtnText} <ArrowIcon />
              </FeatureCTA>
            )}
          </CTAContainer>
        )}
      </SectionFooter>
    </div>
  </Section>
);

export default ProductSection;
