import React from 'react';

import ProductSection, { ProductSectionProps } from './product-section';
import OfferObject from '../../code-blocks/offer-object';

const Offers: React.FC = () => {
  const props: ProductSectionProps = {
    title: 'Offers',
    text: (
      <>
        Create and manage card-linked offers with a variety of{' '}
        <span className="nowrap">retailers - all in one place.</span>
      </>
    ),
    benefits: [
      {
        description:
          'Partner with more businesses on card-linked offers and promotions.',
      },
      {
        description:
          'Access and manage all offers information on one platform.',
      },
      {
        description:
          'Understand buyer behaviour by tracking transactional data across all offers in real time.',
      },
    ],
    codeBlock: OfferObject,
    codeBlockTitle: 'Offer object',
    ctaTitle: 'Interested in using Offers for your business?',
    ctaBtnText: 'Talk to Partnerships',
    ctaTarget: 'contact_partnerships',
    ctaId: 'contactSales-productOffers',
  };

  return <ProductSection {...props} />;
};

export default Offers;
