export default `{
  "id": "49b407ef-508a-432b-900c-a04e5e6ac86c",
  "additionalTerms": "none",
  "brandId": "e4928475-6a39-41e6-8484-951202d43de9",
  "brandName": "Starbucks",
  "brandLogoURL": "https://starbucks.com/logo.png",
  "countryCode": "USA",
  "created": "2018-10-19T12:12:00.000Z",
  "currency": "USD",
  "daysOfWeek": [0,1,2,3,4,5,6],
  "endDate": "2019-06-24T13:13:00.000Z",
  "feeSplit": 70,
  "locationsFile": "./all_locations.csv",
  "locationsTotal": 240,
  "maxRedemption": 1000,
  "maxRedemptionCounter": 730,
  "minTransactionAmount": 150,
  "name": "20% Off Everything",
  "performanceFee": 4,
  "priority": 1,
  "programId": "f446d575-d8c2-4a9e-8e12-ffd970d1a6t",
  "publisherId": "d346d574-d5c2-4a0e-8e02-ffd713fd1a9d",
  "returnPeriod": 30,
  "schemes": [
    "amex",
    "mastercard",
    "visa"
  ],
  "startDate": "2018-10-19T00:00:00.000Z",
  "status": "ACTIVE",
  "type": {
    "name": "discount",
    "value": 20
  },
  "updated": "2018-10-19T12:12:00.000Z",
  "userId": "82c5a9ed-5301-46ab-8599-6bcb0d017cc6"
}`;
